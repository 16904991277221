<template>
	<div class="hello">
		<div class="box">
			<p class="title">登录</p>
			<div class="shu">
				<div class="name">
					<span>账号</span>
					<div>
						<el-input v-model="name" placeholder="请输入后台账号" />
					</div>
				</div>
				<div class="password">
					<span>密码</span>
					<div>
						<el-input type="password" v-model="password" placeholder="请输入后台密码" />
					</div>
				</div>
			</div>
			<div class="btn">
				<el-button @click="sub" v-loading.fullscreen.lock="fullscreenLoading" size='large' type="primary">登录
				</el-button>
			</div>
		</div>

	</div>
</template>

<script>
	//引入axios
	import axios from 'axios';
	import {
		ref
	} from 'vue'
	//引入端口配置文件
	import API_LIST from '../../list.config.js';
	import qs from 'qs'
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'


	export default {
		name: "HelloWorld",
		props: {

		},
		data() {
			return {
				name: '',
				password: '',
				fullscreenLoading: false,
			}

		},
		watch: {

		},
		created() {

		},
		mounted() {

		},
		methods: {
			//跳转到自动核验
			automatic() {
				//this.$router.push({path:'/'})
				this.$router.push({
					path: '/Home',
					query: {
						device_id: this.device_id,
						canteen_id: this.canteen_id,
					}
				});
			},
			//登录
			sub() {
				const that = this;
				this.fullscreenLoading = true
				let obj = {
					name: this.name,
					password: this.password
				}


				axios.post(API_LIST.doLogin, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						(_d)
						this.fullscreenLoading = false;
						if (_d.data.status == 200) {
							localStorage.setItem("u_id", _d.data.user_id);
							ElMessage({
								message: _d.data.msg,
								type: 'success',
							})
							setTimeout(() => {
								that.$router.push({
									path: '/Home',
									query: {

									}
								});
							}, 1500)
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {
						(error);
					});
			},

			//点击上一页
			shang() {
				const that = this;
				if (that.page > 1) {
					that.page--;
					that.his()
				} else {
					ElMessage({
						message: '已经是第一页了',
						type: 'warning',
					})
				}
			},
			//点击下一页
			xia() {
				const that = this;
				if (that.page < that.total_page) {
					that.page++;
					that.his()
				} else {
					ElMessage({
						message: '已经是最后一页了',
						type: 'warning',
					})
				}
			},
			//核验
			hy_btn(str) {
				const that = this;
				let obj = {
					foods: str,
					device_id: this.device_id,
					canteen_id: this.canteen_id,
				}
				let a = qs.stringify(obj)

				axios.post(API_LIST.selelct_order, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {

						document.getElementById('inputId').blur()
						if (_d.data.status == 200) {
							this.data = _d.data.list
							this.erweima = ''
							this.yes()
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
								duration: 5000
							})
							this.erweima = ''
							this.data = {
								product: [],
								user: {},
							}
							setTimeout(() => {
								this.cancel()
							}, 5000)

						}
					})
					.catch(function(error) {

					});


			},
			//取消
			cancel() {
				this.data = {
					product: [],
					user: {},
				}
				this.box_show = false;
				this.focus_btn();
			},
			//确定
			yes() {
				const that = this;
				if (this.data.order_id == '') {
					returnfalse;
				}
				let Base64 = require('js-base64').Base64;
				let aaa = Base64.encode(this.data.user.id)
				let obj = {
					order_id: this.data.order_id,
					type: this.data.type,
					u_id: aaa,
					device_id: this.device_id,
					canteen_id: this.canteen_id,
				}
				let a = qs.stringify(obj)

				axios.post(API_LIST.check_order, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						that.focus_btn()
						this.box_show = false;

						if (_d.data.status == 200) {
							_d.data.list.no = this.renList.length + 1

							this.renList.unshift(_d.data.list)
							//that.chu();
							ElMessage({
								message: '核验完成',
								type: 'success',
							})
							this.data = {
								product: [],
								user: {},
							}
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})
							this.erweima = ''
							this.data = {
								product: [],
								user: {},
							}
						}
					})
					.catch(function(error) {
						(error);
					});
			},
			//
			add0(m) {
				return m < 10 ? '0' + m : m
			},
			format(shijianchuo) {
				//shijianchuo是整数，否则要parseInt转换
				var time = newDate(shijianchuo);
				var y = time.getFullYear();
				var m = time.getMonth() + 1;
				var d = time.getDate();
				var h = time.getHours();
				var mm = time.getMinutes();
				var s = time.getSeconds();
				return y + '-' + this.add0(m) + '-' + this.add0(d);
			},
			//获取光标
			focus_btn() {
				document.getElementById('inputId').focus()
			},
			//
			changes() {
				const that = this;
				this.page = 1;
				that.date = that.datevalue;
				that.his()
			},
			//点击查看详情
			detail(e) {

				this.centerDialogVisible = true;
				this.detail_goods = e;
			},
			//初始列表
			chu() {
				var query = this.$route.query;
				this.device_id = query.device_id;
				this.canteen_id = query.canteen_id;
				this.date = this.format(Date.parse(newDate()))

				let obj = {
					device_id: this.device_id,
					canteen_id: this.canteen_id,
				}
				let a = qs.stringify(obj)


				axios.post(API_LIST.examine_list, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {

						this.loading = false;
						if (_d.data.status == 200) {
							this.renList = _d.data.list
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			}


		}
	};
</script>

<!--Add"scoped"attributetolimitCSStothiscomponentonly-->
<style scoped lang="scss">
	.hello {
		height: 100%;
		background-color: #000;
		text-align: center;
	}

	.box {
		background-color: #fff;
		height: 500px;
		width: 400px;
		display: flex;
		flex-direction: column;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		border-radius: 15px;

		.title {
			font-size: 25px;
			margin: 50px 0 0 0;
		}

		.shu {
			display: flex;
			flex-direction: column;

			//align-items:center;
			.name,
			.password {
				display: flex;
				flex-direction: row;
				align-items: center;
				margin: 40px 0 0 0;

				span {
					width: 70px;
				}

				div {
					width: 300px;
				}
			}
		}

		.btn {
			margin: 50px 0 0 0;
			width: 200px;
			margin: auto;
		}

		.el-button {
			width: 100%;
		}
	}
</style>
