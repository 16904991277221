import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import depot from "../http/index.js";
import './assets/base.css'

import axios from 'axios'
import VueAxios from 'vue-axios'
// 1.引入mitt
import mitt from "mitt"


// Vue.prototype.$http = axios;
// Vue.config.productionTip = false;
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import locale from 'element-plus/lib/locale/lang/zh-cn'



const app = createApp(App)
// 2.使用mitt
app.config.globalProperties.$eventBus = new mitt()


app.use(VueAxios, axios).use(ElementPlus, { locale }).use(store).use(router).mount("#app");



