<template>
  <div class="about">
    <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->
    <Automatic />
  </div>
</template>

<script>
// @ is an alias to /src
import Automatic from "@/components/Automatic.vue";


export default {
  name: "About",
  components: {
    Automatic
  },
};
</script>
<style lang="scss">
	.about{
		height: 100%;
	}
</style>
